<template>
  <!-- fd-footer -->
  <footer class="fd-footer">
    <div class="fdn-footer-top">
      <div class="container">
        <div class="fdn-footer-content">
          <div class="fdn-footer-content-brand">
            <img
              v-lazy="imageSiteLogoHorizontal"
              rel="FastEAD"
            >
            <p>Empresa de sociedade limitada registrada sob o CNJP 36.584.850/0001-21 e Razão Social Serviços e Comercio Eletrônicos I WIN STAR. Ramo Secundário: Treinamento em Desenvolvimento Profissional e Gerencial, Consultoria em Gestão Empresarial.</p>
          </div>
          <div class="fdn-footer-content-menu">
            <strong>CURTA NOSSAS REDES SOCIAIS</strong>
            <ul class="fdn-footer-content-menu-list">
              <li class="fd-nav-header-nav-item">
                <a
                  href="https://www.facebook.com/iwinstaroficial/"
                  target="_blank"
                >Facebook</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a
                  href="https://www.youtube.com/channel/UCjw5Q1m_KMl2_XfbCVhWFNg"
                  target="_blank"
                >Youtube</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a
                  href="https://www.instagram.com/iwinstaroficial/"
                  target="_blank"
                >Instagram</a>
              </li>
            </ul>
          </div>
          <div class="fdn-footer-content-contact">
            <strong>Contatos</strong>
            <ul class="fdn-footer-content-contact-list">
              <li>
                <img v-lazy="imageSiteMapIcon">
                <span>Estrada Geral Rio Corrente, s/n - Bairro: Rio Corrente - Petrolândia - SC</span>
              </li>
              <li>
                <img v-lazy="imageSitePhoneIcon">
                <span>{{ fastTelefoneContato }}</span>
              </li>
              <li>
                <a
                  :href="whatsLink"
                  target="_blank"
                  class="btn btn-primary"
                >Fale com um consultor</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="fdn-footer-bottom">
      <div class="container">
        <span>Todos os direitos reservados @ 2020 Power by Fastead</span>
      </div>
    </div>
  </footer>
  <!-- /fd-footer -->
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import imageSiteLogoHorizontal from "@/assets/images/logo_horizontal.png";
import imageSiteMapIcon from "@/assets/images/map-icon.png";
import imageSitePhoneIcon from "@/assets/images/phone-icon.png";

export default {
  mixins: [methods],
  data: function () {
    return {
      imageSiteLogoHorizontal: "",
      imageSiteMapIcon,
      imageSitePhoneIcon,
      noticiasBlog: [],
      whatsLink: `https://api.whatsapp.com/send?phone=${settings.fastTelefoneContato}&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto`,
    };
  },
  computed: {
    fastTelefoneContato() {
      return (
        settings.fastTelefoneContato.slice(0, 3) +
        " (" +
        settings.fastTelefoneContato.slice(3, 5) +
        ")" +
        " " +
        settings.fastTelefoneContato.slice(5)
      );
    },
  },
  mounted() {
    settings
      .fastLogoPrincipal()
      .then((e) => (this.imageSiteLogoHorizontal = e.default));

    if (settings.endApiConcurseiro == "https://api.concurseiro.com/") {
      //this.listaUltimasBlog();
    }
  },
  methods: {
    async listaUltimasBlog() {
      try {
        let resp = await fetch(
          settings.endApiConcurseiro + "api/cargos/listablog",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let arrayNoticiasBlog = Array.from(json).map((e) => e.ItemArray);
        this.noticiasBlog = arrayNoticiasBlog.slice(0, 3);
        //console.log(this.noticiasBlog);
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
